/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  CryptoTransfer,
  CryptoTransferCreate,
  CryptoTransferCreated,
  PaginatedCryptoTransfers,
  ProblemDetails,
} from '../models/index';
import {
  CryptoTransferFromJSON,
  CryptoTransferToJSON,
  CryptoTransferCreateFromJSON,
  CryptoTransferCreateToJSON,
  CryptoTransferCreatedFromJSON,
  CryptoTransferCreatedToJSON,
  PaginatedCryptoTransfersFromJSON,
  PaginatedCryptoTransfersToJSON,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from '../models/index';

export interface CreateCryptoTransferRequest {
  xIdempotencyKey: string;
  cryptoTransferCreate: CryptoTransferCreate;
}

export interface GetCryptoTransferRequest {
  id: string;
}

export interface GetCryptoTransfersRequest {
  paymentIntentId: string;
  limit?: number;
  offset?: number;
}

/**
 *
 */
export class CryptoTransfersApi extends runtime.BaseAPI {
  /**
   */
  async createCryptoTransferRaw(
    requestParameters: CreateCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoTransferCreated>> {
    if (requestParameters['xIdempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'xIdempotencyKey',
        'Required parameter "xIdempotencyKey" was null or undefined when calling createCryptoTransfer().',
      );
    }

    if (requestParameters['cryptoTransferCreate'] == null) {
      throw new runtime.RequiredError(
        'cryptoTransferCreate',
        'Required parameter "cryptoTransferCreate" was null or undefined when calling createCryptoTransfer().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xIdempotencyKey'] != null) {
      headerParameters['x-idempotency-key'] = String(requestParameters['xIdempotencyKey']);
    }

    const response = await this.request(
      {
        path: `/api/v1/crypto-transfers`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CryptoTransferCreateToJSON(requestParameters['cryptoTransferCreate']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoTransferCreatedFromJSON(jsonValue));
  }

  /**
   */
  async createCryptoTransfer(
    requestParameters: CreateCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoTransferCreated> {
    const response = await this.createCryptoTransferRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCryptoTransferRaw(
    requestParameters: GetCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoTransfer>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getCryptoTransfer().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/crypto-transfers/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoTransferFromJSON(jsonValue));
  }

  /**
   */
  async getCryptoTransfer(
    requestParameters: GetCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoTransfer> {
    const response = await this.getCryptoTransferRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCryptoTransfersRaw(
    requestParameters: GetCryptoTransfersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedCryptoTransfers>> {
    if (requestParameters['paymentIntentId'] == null) {
      throw new runtime.RequiredError(
        'paymentIntentId',
        'Required parameter "paymentIntentId" was null or undefined when calling getCryptoTransfers().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['limit'] != null) {
      queryParameters['limit'] = requestParameters['limit'];
    }

    if (requestParameters['offset'] != null) {
      queryParameters['offset'] = requestParameters['offset'];
    }

    if (requestParameters['paymentIntentId'] != null) {
      queryParameters['payment_intent_id'] = requestParameters['paymentIntentId'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/crypto-transfers`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCryptoTransfersFromJSON(jsonValue));
  }

  /**
   */
  async getCryptoTransfers(
    requestParameters: GetCryptoTransfersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedCryptoTransfers> {
    const response = await this.getCryptoTransfersRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
