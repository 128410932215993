/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { CryptoExecution, PaginatedCryptoExecutions, ProblemDetails } from '../models/index';
import {
  CryptoExecutionFromJSON,
  CryptoExecutionToJSON,
  PaginatedCryptoExecutionsFromJSON,
  PaginatedCryptoExecutionsToJSON,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from '../models/index';

export interface GetCryptoExecutionRequest {
  id: string;
}

export interface GetCryptoExecutionsRequest {
  paymentIntentId: string;
  merchantRefundId: string;
  limit?: number;
  offset?: number;
}

/**
 *
 */
export class CryptoExecutionsApi extends runtime.BaseAPI {
  /**
   */
  async getCryptoExecutionRaw(
    requestParameters: GetCryptoExecutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoExecution>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getCryptoExecution().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/crypto-executions/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoExecutionFromJSON(jsonValue));
  }

  /**
   */
  async getCryptoExecution(
    requestParameters: GetCryptoExecutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoExecution> {
    const response = await this.getCryptoExecutionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCryptoExecutionsRaw(
    requestParameters: GetCryptoExecutionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedCryptoExecutions>> {
    if (requestParameters['paymentIntentId'] == null) {
      throw new runtime.RequiredError(
        'paymentIntentId',
        'Required parameter "paymentIntentId" was null or undefined when calling getCryptoExecutions().',
      );
    }

    if (requestParameters['merchantRefundId'] == null) {
      throw new runtime.RequiredError(
        'merchantRefundId',
        'Required parameter "merchantRefundId" was null or undefined when calling getCryptoExecutions().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['limit'] != null) {
      queryParameters['limit'] = requestParameters['limit'];
    }

    if (requestParameters['offset'] != null) {
      queryParameters['offset'] = requestParameters['offset'];
    }

    if (requestParameters['paymentIntentId'] != null) {
      queryParameters['payment_intent_id'] = requestParameters['paymentIntentId'];
    }

    if (requestParameters['merchantRefundId'] != null) {
      queryParameters['merchant_refund_id'] = requestParameters['merchantRefundId'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/crypto-executions`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCryptoExecutionsFromJSON(jsonValue));
  }

  /**
   */
  async getCryptoExecutions(
    requestParameters: GetCryptoExecutionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedCryptoExecutions> {
    const response = await this.getCryptoExecutionsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
