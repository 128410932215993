/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentIntentMerchantRefundOrder } from './PaymentIntentMerchantRefundOrder';
import {
  PaymentIntentMerchantRefundOrderFromJSON,
  PaymentIntentMerchantRefundOrderFromJSONTyped,
  PaymentIntentMerchantRefundOrderToJSON,
  PaymentIntentMerchantRefundOrderToJSONTyped,
} from './PaymentIntentMerchantRefundOrder';
import type { PaymentIntentMerchantRefundStatus } from './PaymentIntentMerchantRefundStatus';
import {
  PaymentIntentMerchantRefundStatusFromJSON,
  PaymentIntentMerchantRefundStatusFromJSONTyped,
  PaymentIntentMerchantRefundStatusToJSON,
  PaymentIntentMerchantRefundStatusToJSONTyped,
} from './PaymentIntentMerchantRefundStatus';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
  CryptoWalletAddressWithTagToJSONTyped,
} from './CryptoWalletAddressWithTag';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON, FiatMoneyToJSONTyped } from './FiatMoney';

/**
 *
 * @export
 * @interface PaymentIntentMerchantRefund
 */
export interface PaymentIntentMerchantRefund {
  /**
   * Opaque object identifier
   * @type {string}
   * @memberof PaymentIntentMerchantRefund
   */
  cryptoExecutionId?: string;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof PaymentIntentMerchantRefund
   */
  id: string;
  /**
   *
   * @type {PaymentIntentMerchantRefundOrder}
   * @memberof PaymentIntentMerchantRefund
   */
  order?: PaymentIntentMerchantRefundOrder;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof PaymentIntentMerchantRefund
   */
  refundDestination?: CryptoWalletAddressWithTag;
  /**
   *
   * @type {FiatMoney}
   * @memberof PaymentIntentMerchantRefund
   */
  refundFiat: FiatMoney;
  /**
   *
   * @type {PaymentIntentMerchantRefundStatus}
   * @memberof PaymentIntentMerchantRefund
   */
  status: PaymentIntentMerchantRefundStatus;
}

/**
 * Check if a given object implements the PaymentIntentMerchantRefund interface.
 */
export function instanceOfPaymentIntentMerchantRefund(value: object): value is PaymentIntentMerchantRefund {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('refundFiat' in value) || value['refundFiat'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function PaymentIntentMerchantRefundFromJSON(json: any): PaymentIntentMerchantRefund {
  return PaymentIntentMerchantRefundFromJSONTyped(json, false);
}

export function PaymentIntentMerchantRefundFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentMerchantRefund {
  if (json == null) {
    return json;
  }
  return {
    cryptoExecutionId: json['crypto_execution_id'] == null ? undefined : json['crypto_execution_id'],
    id: json['id'],
    order: json['order'] == null ? undefined : PaymentIntentMerchantRefundOrderFromJSON(json['order']),
    refundDestination:
      json['refund_destination'] == null ? undefined : CryptoWalletAddressWithTagFromJSON(json['refund_destination']),
    refundFiat: FiatMoneyFromJSON(json['refund_fiat']),
    status: PaymentIntentMerchantRefundStatusFromJSON(json['status']),
  };
}

export function PaymentIntentMerchantRefundToJSON(json: any): PaymentIntentMerchantRefund {
  return PaymentIntentMerchantRefundToJSONTyped(json, false);
}

export function PaymentIntentMerchantRefundToJSONTyped(
  value?: PaymentIntentMerchantRefund | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    crypto_execution_id: value['cryptoExecutionId'],
    id: value['id'],
    order: PaymentIntentMerchantRefundOrderToJSON(value['order']),
    refund_destination: CryptoWalletAddressWithTagToJSON(value['refundDestination']),
    refund_fiat: FiatMoneyToJSON(value['refundFiat']),
    status: PaymentIntentMerchantRefundStatusToJSON(value['status']),
  };
}
