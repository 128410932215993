/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaginatedCryptoExecutionsQuery } from './PaginatedCryptoExecutionsQuery';
import {
  PaginatedCryptoExecutionsQueryFromJSON,
  PaginatedCryptoExecutionsQueryFromJSONTyped,
  PaginatedCryptoExecutionsQueryToJSON,
  PaginatedCryptoExecutionsQueryToJSONTyped,
} from './PaginatedCryptoExecutionsQuery';
import type { CryptoExecution } from './CryptoExecution';
import {
  CryptoExecutionFromJSON,
  CryptoExecutionFromJSONTyped,
  CryptoExecutionToJSON,
  CryptoExecutionToJSONTyped,
} from './CryptoExecution';

/**
 *
 * @export
 * @interface PaginatedCryptoExecutions
 */
export interface PaginatedCryptoExecutions {
  /**
   *
   * @type {PaginatedCryptoExecutionsQuery}
   * @memberof PaginatedCryptoExecutions
   */
  query: PaginatedCryptoExecutionsQuery;
  /**
   *
   * @type {Array<CryptoExecution>}
   * @memberof PaginatedCryptoExecutions
   */
  items: Array<CryptoExecution>;
}

/**
 * Check if a given object implements the PaginatedCryptoExecutions interface.
 */
export function instanceOfPaginatedCryptoExecutions(value: object): value is PaginatedCryptoExecutions {
  if (!('query' in value) || value['query'] === undefined) return false;
  if (!('items' in value) || value['items'] === undefined) return false;
  return true;
}

export function PaginatedCryptoExecutionsFromJSON(json: any): PaginatedCryptoExecutions {
  return PaginatedCryptoExecutionsFromJSONTyped(json, false);
}

export function PaginatedCryptoExecutionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedCryptoExecutions {
  if (json == null) {
    return json;
  }
  return {
    query: json['query'],
    items: (json['items'] as Array<any>).map(CryptoExecutionFromJSON),
  };
}

export function PaginatedCryptoExecutionsToJSON(json: any): PaginatedCryptoExecutions {
  return PaginatedCryptoExecutionsToJSONTyped(json, false);
}

export function PaginatedCryptoExecutionsToJSONTyped(
  value?: PaginatedCryptoExecutions | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    query: value['query'],
    items: (value['items'] as Array<any>).map(CryptoExecutionToJSON),
  };
}
