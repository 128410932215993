/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaginatedCryptoTransferItem } from './PaginatedCryptoTransferItem';
import {
  PaginatedCryptoTransferItemFromJSON,
  PaginatedCryptoTransferItemFromJSONTyped,
  PaginatedCryptoTransferItemToJSON,
  PaginatedCryptoTransferItemToJSONTyped,
} from './PaginatedCryptoTransferItem';
import type { PaginatedCryptoTransfersQuery } from './PaginatedCryptoTransfersQuery';
import {
  PaginatedCryptoTransfersQueryFromJSON,
  PaginatedCryptoTransfersQueryFromJSONTyped,
  PaginatedCryptoTransfersQueryToJSON,
  PaginatedCryptoTransfersQueryToJSONTyped,
} from './PaginatedCryptoTransfersQuery';

/**
 *
 * @export
 * @interface PaginatedCryptoTransfers
 */
export interface PaginatedCryptoTransfers {
  /**
   *
   * @type {PaginatedCryptoTransfersQuery}
   * @memberof PaginatedCryptoTransfers
   */
  query: PaginatedCryptoTransfersQuery;
  /**
   *
   * @type {Array<PaginatedCryptoTransferItem>}
   * @memberof PaginatedCryptoTransfers
   */
  items: Array<PaginatedCryptoTransferItem>;
}

/**
 * Check if a given object implements the PaginatedCryptoTransfers interface.
 */
export function instanceOfPaginatedCryptoTransfers(value: object): value is PaginatedCryptoTransfers {
  if (!('query' in value) || value['query'] === undefined) return false;
  if (!('items' in value) || value['items'] === undefined) return false;
  return true;
}

export function PaginatedCryptoTransfersFromJSON(json: any): PaginatedCryptoTransfers {
  return PaginatedCryptoTransfersFromJSONTyped(json, false);
}

export function PaginatedCryptoTransfersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedCryptoTransfers {
  if (json == null) {
    return json;
  }
  return {
    query: PaginatedCryptoTransfersQueryFromJSON(json['query']),
    items: (json['items'] as Array<any>).map(PaginatedCryptoTransferItemFromJSON),
  };
}

export function PaginatedCryptoTransfersToJSON(json: any): PaginatedCryptoTransfers {
  return PaginatedCryptoTransfersToJSONTyped(json, false);
}

export function PaginatedCryptoTransfersToJSONTyped(
  value?: PaginatedCryptoTransfers | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    query: PaginatedCryptoTransfersQueryToJSON(value['query']),
    items: (value['items'] as Array<any>).map(PaginatedCryptoTransferItemToJSON),
  };
}
