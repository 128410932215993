/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
  CryptoWalletAddressWithTagToJSONTyped,
} from './CryptoWalletAddressWithTag';

/**
 *
 * @export
 * @interface PaymentIntentMerchantRefundPatch
 */
export interface PaymentIntentMerchantRefundPatch {
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof PaymentIntentMerchantRefundPatch
   */
  refundDestination: CryptoWalletAddressWithTag;
  /**
   *
   * @type {string}
   * @memberof PaymentIntentMerchantRefundPatch
   */
  verificationToken: string;
}

/**
 * Check if a given object implements the PaymentIntentMerchantRefundPatch interface.
 */
export function instanceOfPaymentIntentMerchantRefundPatch(value: object): value is PaymentIntentMerchantRefundPatch {
  if (!('refundDestination' in value) || value['refundDestination'] === undefined) return false;
  if (!('verificationToken' in value) || value['verificationToken'] === undefined) return false;
  return true;
}

export function PaymentIntentMerchantRefundPatchFromJSON(json: any): PaymentIntentMerchantRefundPatch {
  return PaymentIntentMerchantRefundPatchFromJSONTyped(json, false);
}

export function PaymentIntentMerchantRefundPatchFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentMerchantRefundPatch {
  if (json == null) {
    return json;
  }
  return {
    refundDestination: CryptoWalletAddressWithTagFromJSON(json['refund_destination']),
    verificationToken: json['verification_token'],
  };
}

export function PaymentIntentMerchantRefundPatchToJSON(json: any): PaymentIntentMerchantRefundPatch {
  return PaymentIntentMerchantRefundPatchToJSONTyped(json, false);
}

export function PaymentIntentMerchantRefundPatchToJSONTyped(
  value?: PaymentIntentMerchantRefundPatch | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    refund_destination: CryptoWalletAddressWithTagToJSON(value['refundDestination']),
    verification_token: value['verificationToken'],
  };
}
