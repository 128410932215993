/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaginatedCryptoTransfersQuery
 */
export interface PaginatedCryptoTransfersQuery {
  /**
   *
   * @type {number}
   * @memberof PaginatedCryptoTransfersQuery
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedCryptoTransfersQuery
   */
  limit?: number;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof PaginatedCryptoTransfersQuery
   */
  paymentIntentId: string;
}

/**
 * Check if a given object implements the PaginatedCryptoTransfersQuery interface.
 */
export function instanceOfPaginatedCryptoTransfersQuery(value: object): value is PaginatedCryptoTransfersQuery {
  if (!('paymentIntentId' in value) || value['paymentIntentId'] === undefined) return false;
  return true;
}

export function PaginatedCryptoTransfersQueryFromJSON(json: any): PaginatedCryptoTransfersQuery {
  return PaginatedCryptoTransfersQueryFromJSONTyped(json, false);
}

export function PaginatedCryptoTransfersQueryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedCryptoTransfersQuery {
  if (json == null) {
    return json;
  }
  return {
    offset: json['offset'] == null ? undefined : json['offset'],
    limit: json['limit'] == null ? undefined : json['limit'],
    paymentIntentId: json['payment_intent_id'],
  };
}

export function PaginatedCryptoTransfersQueryToJSON(json: any): PaginatedCryptoTransfersQuery {
  return PaginatedCryptoTransfersQueryToJSONTyped(json, false);
}

export function PaginatedCryptoTransfersQueryToJSONTyped(
  value?: PaginatedCryptoTransfersQuery | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    offset: value['offset'],
    limit: value['limit'],
    payment_intent_id: value['paymentIntentId'],
  };
}
