import { reactive, ref } from 'vue';

export function useLoading(initialLoadingState?: boolean) {
  const isLoading = ref(!!initialLoadingState);

  return reactive({
    isLoading: isLoading,
    startLoading: () => (isLoading.value = true),
    stopLoading: () => (isLoading.value = false),
    toggle: () => (isLoading.value = !isLoading.value),
  });
}
